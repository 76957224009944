<script setup>
    import AppStatus from 'o365.pwa.vue.components.navbar.offCanvas.AppStatus.vue';

    const props = defineProps(['pwaState', 'isAccordionOpen']);
</script>

<template>
    <Transition appear>
        <div v-if="isAccordionOpen">
            <AppStatus :pwaState="pwaState"></AppStatus>
        </div>
    </Transition>
</template>